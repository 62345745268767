import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateDarkWebStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createDarkWebStreamDocument = graphql(/* GraphQL */ `
	mutation CreateDarkWebStream($params: CreateDarkWebStreamParams!) {
		createDarkWebStream(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateDarkWebStreamParams;
	userId?: string;
}

const createDarkWebStream = ({ params, userId }: Args) =>
	client(userId)
		.request(createDarkWebStreamDocument, { params })
		.then((res) => res.createDarkWebStream);

export function useCreateDarkWebStream() {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateDarkWebStreamParams) =>
			createDarkWebStream({ params, userId }),
		{
			onSettled: () => {
				/** invalidate every streams query in case it includes the new stream */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"streams",
				]);
			},
		}
	);
}
